import { FC, HTMLAttributes, useCallback, useRef, useState } from 'react';
import block from 'utils/bem-css-module';

import { Hint } from 'components/Hint';
import { YANDEX_METRIC_ID } from 'constants/common';
import { useClickOutside } from 'hooks/use-click-outside';

import style from './support-widget.scss';

const b = block(style);


export const SupportWidget: FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	...rest
}) => {
	const containerRef = useRef(null);
	useClickOutside(containerRef, useCallback(() => setIsOpen(false), []));
	const [isOpen, setIsOpen] = useState(false);
	const onClick = () => {
		setIsOpen(isOpen => !isOpen);
	};
	return (
		<div
			className={b({ open: isOpen }).mix(className)}
			ref={containerRef}
			{...rest}
		>
			<ul className={b('list')}>
				<li className={b('item', { type: 'email' })}>
					<a
						href='mailto:service@printbox.io'
						title='Написать на почту'
						onClick={() => ym(YANDEX_METRIC_ID, 'reachGoal', 'email')}
					/>
				</li>
				<li className={b('item', { type: 'tg' })}>
					<a
						href='https://t.me/printbox_io_bot'
						title='Написать в телеграмм'
						target='_blank'
						rel='noreferrer'
						onClick={() => ym(YANDEX_METRIC_ID, 'reachGoal', 'tg_bot')}
					/>
				</li>
				<li className={b('item', { type: 'chat' })}>
					<a
						href='/profile/conversations/'
						title='Поддержка на сайте'
						target='_blank'
						rel='noreferrer'
						onClick={() => ym(YANDEX_METRIC_ID, 'reachGoal', 'site_support')}
					/>
				</li>
			</ul>
			<button className={b('button')} onClick={onClick} aria-labelledby='support-widget-hint' />
			<Hint
				horizontal='right'
				vertical='top'
				className={b('hint')}
				id='support-widget-hint'
				onClick={onClick}
			>
				Нужна помощь?
			</Hint>
		</div>
	);
};
